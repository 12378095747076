import React, { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import HeaderPatientInfo from '../HeaderPatientInfo';
import { PatientInfoVisibility } from '../../types/PatientInfoVisibility';

import {
  useUpsertAnnotationData,
  annotationService,
  getAnnotationDataDynamicQueryKey,
} from '@xylexa/xylexa-app';
import { Link, useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import {
  CreateReportModal,
  REPORT_IDS_KEY,
  useAuthenticationContext,
  useXylexaAppContext,
  UserInfo,
  useToast,
} from '@xylexa/xylexa-app';
import { useSearchParams } from '@ohif/app/src/hooks';
import secureLocalStorage from 'react-secure-storage';

import { createPortal } from 'react-dom';
import { Button, Select, Tooltip } from '@ohif/ui';
import { useQueryClient } from '@tanstack/react-query';

function Header({
  children,
  menuOptions,
  isReturnEnabled = true,
  onClickReturnButton,
  isSticky = false,
  WhiteLabeling,
  showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED,
  servicesManager,
  Secondary,
  appConfig,
  ...props
}: withAppTypes): ReactNode {
  const { t } = useTranslation('Header');
  const { showToast } = useToast();
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);

  const { setIsInsideViewer, isChangeInAnnotationViewPort, setIsChangeInAnnotationViewPort } =
    useXylexaAppContext();

  const { selectedServer, setSelectedServer, serverOptions, userInfo } = useAuthenticationContext();

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      setIsInsideViewer(false);
      onClickReturnButton();
    }
  };

  const { getAnnotationDataArray } = annotationService();

  const searchParams = useSearchParams();
  const studyInstanceUidFromUrl = searchParams.get('StudyInstanceUIDs');

  const { mutate: handleUpsertAnnotationData } = useUpsertAnnotationData();
  const queryClient = useQueryClient();

  const handleSaveAnnotationClick = async () => {
    const { measurementService } = servicesManager.services;
    const annotationData = await getAnnotationDataArray(measurementService.annotationsDataArr);
    const body = {
      User: userInfo.id,
      study_instance_id: studyInstanceUidFromUrl,
      annotation_data: annotationData,
      measurement_data: measurementService.annotationsMeasurementDataArr,
    };

    handleUpsertAnnotationData(body, {
      onSuccess: res => {
        queryClient.invalidateQueries({
          queryKey: getAnnotationDataDynamicQueryKey(studyInstanceUidFromUrl),
        });
        showToast({
          content: 'Annotations Saved!',
          type: 'success',
        });

        measurementService.isChangeInViewPortAnnotationsDetected = false;
        setIsChangeInAnnotationViewPort(false);

        if (showAnnotationModal) {
          navigate('/');
        }
      },
      onError(err) {
        showToast({
          content: 'Annotations Submission Failed!',
          type: 'error',
        });
      },
    });
  };

  const navigate = useNavigate();

  const reportIds = secureLocalStorage.getItem(REPORT_IDS_KEY);

  const isReported = studyId => {
    const response = reportIds?.data.study_ids.find(id => {
      return id === studyId;
    });

    return response;
  };

  const [showModal, setShowModal] = useState(false);

  const onSelectServer = selectedServer => {
    setSelectedServer(selectedServer);
  };

  const handleBackWithSaveAnnotations = () => {
    handleSaveAnnotationClick();
  };
  const handleBackWithoutSaveAnnotations = () => {
    onClickReturn();
  };
  const handleOpenAnnotationModal = () => {
    setShowAnnotationModal(true);
  };
  const handleCloseAnnotationModal = () => {
    setShowAnnotationModal(false);
  };

  return (
    <NavBar
      className="bg-secondary-dark flex flex-row items-center justify-between border-black p-4"
      isSticky={isSticky}
      {...props}
    >
      <div
        className={classNames('mr-3 inline-flex items-center', isReturnEnabled && 'cursor-pointer')}
        onClick={isChangeInAnnotationViewPort ? handleOpenAnnotationModal : onClickReturn}
        data-cy="return-to-work-list"
      >
        {isReturnEnabled && (
          <Icon
            name="chevron-left"
            className="text-secondary-main w-12"
          />
        )}
        {WhiteLabeling?.createLogoComponentFn?.(React, props) || (
          <Link to={'/'}>
            <Svg
              name="logo-xylexa"
              className="w-40 sm:w-24 md:w-32 lg:w-36"
            />
          </Link>
        )}
      </div>

      {Secondary}

      <div className="flex flex-row items-center gap-3">
        {children}

        {isReturnEnabled && (
          <div>
            <div className="flex flex-row gap-5">
              <div
                onClick={() => {
                  isReported(studyInstanceUidFromUrl)
                    ? navigate(`/viewer/view-report/${studyInstanceUidFromUrl}`)
                    : setShowModal(true);
                }}
              >
                <Tooltip content={'Patient Report'}>
                  <Icon
                    name="write-report"
                    className="h-[1.5rem] w-[1.5rem]"
                  />
                </Tooltip>
              </div>
              <Tooltip content={'Save Annotations'}>
                <button
                  id="save-annotation-button"
                  onClick={handleSaveAnnotationClick}
                >
                  {' '}
                  <Icon
                    name="save-icon"
                    className="fill-wh h-[1.5rem] w-[1.5rem]"
                  />
                </button>
              </Tooltip>
            </div>

            {showModal &&
              createPortal(
                <CreateReportModal setShowModal={setShowModal} />,
                document.body,
                'createReportModal'
              )}
            <Modal
              open={showAnnotationModal}
              onClose={handleCloseAnnotationModal}
            >
              <h1 className="text-xl font-semibold text-black">Do you want to save changes?</h1>
              <p className="text-lg text-black">
                If you choose "No", the content will be deleted permanently, else the annotations
                will be saved.
              </p>
              <br />
              <div className="flex justify-end gap-2">
                <Button onClick={handleBackWithoutSaveAnnotations}>No</Button>
                <Button onClick={handleBackWithSaveAnnotations}>Yes</Button>
              </div>
            </Modal>
          </div>
        )}
      </div>

      <div className="flex flex-row">
        {showPatientInfo !== PatientInfoVisibility.DISABLED && (
          <HeaderPatientInfo
            servicesManager={servicesManager}
            appConfig={appConfig}
          />
        )}
        {!isReturnEnabled && (
          <div className="d-flex">
            <Select
              id="select-server"
              className="border-primary-main min-w-28 relative mr-3 w-28 bg-transparent text-white"
              value={selectedServer}
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={true}
              hideSelectedOptions={true}
              options={serverOptions}
              onChange={onSelectServer}
            />
          </div>
        )}

        {!isReturnEnabled && <UserInfo />}

        <Dropdown
          id="options"
          showDropdownIcon={false}
          list={menuOptions}
          alignment="right"
        >
          <IconButton
            id={'options-settings-icon'}
            variant="text"
            color="inherit"
            size="initial"
            className="text-primary-active hover:bg-primary-dark h-full w-full"
          >
            <Icon name="icon-settings" />
          </IconButton>
        </Dropdown>
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  showPatientInfo: PropTypes.string,
  servicesManager: PropTypes.object,
};

export default Header;
