import { useQuery } from '@tanstack/react-query';
import { getAnnotationDataDynamicQueryKey } from './queryKeys';
import axios from 'axios';
import { API_URL } from '../../constants';
import { useAuthenticationContext } from '../../context';
import { useGetRequestOptions } from '../../hooks';

export const useGetAnnotationData = (userId, studyInstanceId) => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);

  return useQuery({
    queryKey: getAnnotationDataDynamicQueryKey(studyInstanceId),
    queryFn: () =>
      axios.get(
        `${API_URL}v1/annotations/${userId}/?study_instance_id=${studyInstanceId}`,
        options
      ),
    retry: false,
  });
};
