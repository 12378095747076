import React, { useEffect } from 'react';
import {
  useAuthenticationContext,
  useGetCloudServerConfigs,
  useGetUserInfo,
} from '@xylexa/xylexa-app';

import { Login } from './components/Login';
import mixpanel from 'mixpanel-browser';

import App from './App';
import { checkPacsInstance } from '@xylexa/xylexa-app';

mixpanel.init(process.env.MIXPANEL_TOKEN, {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

export const XylexaLoginPage = props => {
  const { serverConfigs, setServerConfigs, selectedServer, setUserInfo } =
    useAuthenticationContext();
  const { data: userInfo, isFetched: isUserInfoFetched, status: userInfoStatus } = useGetUserInfo();
  if (userInfoStatus === 'success') {
    setUserInfo(userInfo);
  }

  const { data: serverConfigsData, isFetched: isUserCloudConfigsFetched } =
    useGetCloudServerConfigs({
      groupId: userInfo?.groups?.[0]?.id || '',
      enabled: isUserInfoFetched,
      pacs_type: selectedServer?.value,
      serverConfigCallKey: selectedServer?.value,
    });

  useEffect(() => {
    if (isUserCloudConfigsFetched) {
      setServerConfigs(serverConfigsData?.data);
      checkPacsInstance({ serverConfigsData, setServerConfigs });
    }
  }, [isUserCloudConfigsFetched, serverConfigsData, setServerConfigs]);

  const config = {
    ...window.config,
    dataSources: [
      {
        ...window.config?.dataSources?.[0],
        configuration: serverConfigs,
      },
    ],
  };

  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(
      window,
      document,
      'clarity',
      'script',
      process.env.MS_CLARITY_PROJECT_ID ? process.env.MS_CLARITY_PROJECT_ID : 'p937irhft2'
    );
  }, []);

  return serverConfigs ? (
    <App
      config={config}
      defaultExtensions={props.defaultExtensions}
      defaultModes={props.defaultModes}
    />
  ) : (
    <Login />
  );
};
