export const authTokenQueryKey = ['user', 'auth'];

export const userDataQueryKey = ['user', 'data'];

export const patientAiReportQueryKey = ['patient', 'ai', 'report'];
export const patientReportIdsQueryKey = ['patient', 'report', 'ids'];

export const annotationDataQueryKey = ['annotation', 'data', 'query'];

export const getAnnotationDataDynamicQueryKey = (annotationDataDynamicQueryKey: string) => {
  return [['annotation', 'data', 'query', annotationDataDynamicQueryKey]];
};
export const getUserConfigDynamicQueryKey = (userConfigDynamickey: string) => {
  return [['user', 'server', 'configs', userConfigDynamickey]];
};
export const getPatientReportDynamicQueryKey = (patientReportDynamickey: string) => {
  return [['patient', 'report', patientReportDynamickey]];
};
