import axios, { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { API_URL } from '../../constants';
import { getUserConfigDynamicQueryKey, userDataQueryKey } from './queryKeys';
import { ServerConfigs } from '../../types';
import { useGetRequestOptions } from '../../hooks';
import { useAuthenticationContext } from '../../context';

export type GetServerConfigsProps = {
  groupId: string | null;
  enabled?: boolean;
  pacs_type: string;
  serverConfigCallKey?: string;
};

export type UserInfo = {
  url: string;
  username: string;
  email: string;
  groups: { id: string; name: string; url: string }[];
};

export const useGetUserInfo = (): UseQueryResult<UserInfo> => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);

  return useQuery({
    queryKey: userDataQueryKey,
    queryFn: () => axios.get(`${API_URL}v1/user/`, options).then(res => res.data),
    enabled: !!authToken,
  });
};

export const useGetCloudServerConfigs = ({
  groupId,
  pacs_type = 'cloud',
  enabled = false,
  serverConfigCallKey,
}: GetServerConfigsProps): UseQueryResult<AxiosResponse<ServerConfigs>, Error> => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);

  return useQuery({
    queryKey: getUserConfigDynamicQueryKey(serverConfigCallKey),
    queryFn: () =>
      axios.get(`${API_URL}v1/groupprofiles/?group_id=${groupId}&pacs_type=${pacs_type}`, options),
    enabled: enabled,
  });
};
