// Key variables used in local storage
export const AUTH_TOKEN_STORAGE_KEY = 'auth-token';
export const USER_DATA_STORAGE_KEY = 'user-data';
export const SERVER_CONFIG_STORAGE_KEY = 'server-configs';
export const HAS_SUBMITTED_KEY = 'has-submitted';
export const IS_INSIDE_VIEWER_KEY = 'is-inside-viewer';
export const ORTHANC_TYPE_KEY = 'orthan-type';
export const PATIENT_INFO_KEY = 'patient-info';
export const TYPE_KEY = 'type';
export const REPORT_IDS_KEY = 'report-ids';
