import axios from 'axios';

import { useQuery } from '@tanstack/react-query';
import { API_URL } from './../../constants';
import {
  getPatientReportDynamicQueryKey,
  patientAiReportQueryKey,
  patientReportIdsQueryKey,
} from './queryKeys';

import { useGetRequestOptions } from '../../hooks';
import { useAuthenticationContext } from '../../context';
export type StudyId = string;
export type QueryKey = string;

/**
 *
 * @param {string} studyId
 * @param {string} queryKey
 * @returns {object} query funtion which gives us report data against the
 * provided study Id
 *
 * @example
 * const studyId = '1234.56789.654321'
 * const queryKey = 'your-key'
 * const { data: studyReportData } = useGetStudyReport(studyId, queryKey);
 *
 * console.log({studyReportData})
 */

export const useGetStudyReport = (studyId: StudyId, queryKey: QueryKey) => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: getPatientReportDynamicQueryKey(queryKey),
    queryFn: async () => await axios.get(`${API_URL}v1/report/?study_id=${studyId}`, options),
    retry: 0,
  });
};

/**
 *
 * @param {string} studyId
 * @returns {object} query funtion which gives us report data against the
 * provided study Id
 *
 * @example
 * const studyId = '4321.56789.654321'
 * const queryKey = 'your-key'
 * const { data: studyReportAiData } = useGetAiReport(studyId);
 *
 * console.log({studyReportAiData})
 */

export const useGetAiReport = (studyId: StudyId, selectedModality) => {
  const { authToken } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);

  return useQuery({
    queryKey: patientAiReportQueryKey,
    queryFn: async () => await axios.get(`${API_URL}v1/aireport/?study_id=${studyId}`, options),
    enabled: selectedModality,
    retry: 0,
  });
};

export const useGetReportIds = () => {
  const { authToken, clearStorage } = useAuthenticationContext();
  const options = useGetRequestOptions(authToken);
  return useQuery({
    queryKey: patientReportIdsQueryKey,
    queryFn: async () =>
      await axios.get(`${API_URL}v1/reportIds/`, options).catch(error => {
        if (error.response.status === 401) {
          clearStorage();
          window.location.reload();
        }
        return;
      }),
  });
};
